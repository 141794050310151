import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Table, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import api from "../../../utils/agentApi";
import api2 from "../../../utils/api";

function ViewAgent() {
  let { agentId } = useParams();

  const [agent, setAgent] = useState({});
  const [userPrompt, setUserPrompt] = useState("");
  const [runResult, setRunResult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [websiteUrl, setWebsiteUrl] = useState(() => localStorage.getItem('websiteUrl') || "");
  const [isRunning, setIsRunning] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    const fetchAgent = async () => {
      setIsLoading(true);
      const response = await api.post(`/get_agent`, { agent_id: agentId });
      setAgent(response.data.data);
      setEditData(response.data.data); // Initialize edit data with agent data
      setIsLoading(false);
    };
    fetchAgent();

    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await api2.get('/report/all', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProjects(response.data);
        
        // Get stored project and file if they exist
        const storedProject = localStorage.getItem('selectedProject');
        const storedFile = localStorage.getItem('selectedFile');
        
        if (storedProject) {
          setSelectedProject(storedProject);
          // Fetch files for the stored project
          const filesResponse = await api2.get(`/report/files/${storedProject}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setFiles(filesResponse.data);
          
          if (storedFile) {
            setSelectedFile(storedFile);
          }
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, [agentId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const toggleEdit = async () => {
    if (isEditing) {
      try {
        await api.post(`/new_or_update_agent`, { agent_id: agentId, ...editData });
        setAgent(editData);
      } catch (error) {
        console.error(error);
        return;
      }
    }
    setIsEditing(!isEditing);
  };


  async function RunAgent() {
    setIsRunning(true);
    try {
      const agentData = {
        agent_id: agentId,
        user_prompt: userPrompt,
        website_url: websiteUrl,
        file_key: selectedFile
      };
      const response = await api.post("/run_agent", agentData);
      const result = response.data.result;
      
      let formattedResult = '';
      
      const formatValue = (value) => {
        if (typeof value === 'object' && value !== null) {
          return formatObject(value, '  ');
        }
        if (typeof value === 'number') {
          return Number.isInteger(value) ? 
            value.toLocaleString() : 
            value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return value;
      };

      const formatObject = (obj, indent = '') => {
        return Object.entries(obj)
          .map(([key, value]) => {
            const formattedKey = key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

            if (value === null || value === undefined) {
              return `${indent}• ${formattedKey}: -`;
            }
            if (Array.isArray(value)) {
              return `${indent}• ${formattedKey}:\n${value.map(item => `${indent}  - ${formatValue(item)}`).join('\n')}`;
            }
            if (typeof value === 'object' && !Array.isArray(value)) {
              return `${indent}• ${formattedKey}:\n${formatObject(value, indent + '  ')}`;
            }
            return `${indent}• ${formattedKey}: ${formatValue(value)}`;
          })
          .join('\n');
      };

      if (Array.isArray(result)) {
        formattedResult = result
          .map(item => formatObject(item))
          .join('\n\n');
      } else if (typeof result === 'object' && result !== null) {
        formattedResult = formatObject(result);
      } else {
        formattedResult = String(result);
      }
      
      setRunResult(formattedResult);
    } catch (error) {
      console.error(error);
      setRunResult("Error running agent: " + error.message);
    } finally {
      setIsRunning(false);
    }
  }

  const handleProjectChange = async (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    setSelectedFile(""); // Reset selected file when project changes
    
    localStorage.setItem('selectedProject', projectId);
    localStorage.removeItem('selectedFile'); // Clear stored file when project changes
    
    if (projectId) {
      try {
        const token = localStorage.getItem('token');
        const response = await api2.get(`/report/files/${projectId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFiles(response.data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    } else {
      setFiles([]); // Clear files if no project selected
    }
  };

  const handleFileChange = (e) => {
    const fileValue = e.target.value;
    setSelectedFile(fileValue);
    localStorage.setItem('selectedFile', fileValue);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/upload_customer_file', { project: selectedProject }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      
      if (response.status === 200) {
        alert("Project selected successfully");
      }
    }
    catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  };

  const handleWebsiteUrlChange = (e) => {
    const url = e.target.value;
    setWebsiteUrl(url);
    localStorage.setItem('websiteUrl', url);
  };

  return (
    <Container className="mb-5">
      {isLoading && <p>Loading agent...</p>}
      {!isLoading && (
        <>
          <h1>{agent.name}</h1>
          <Row>
            <Col xs={2}>
              <strong>Name: </strong>
            </Col>
            <Col>{agent.name}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Description: </strong>
            </Col>
            <Col>{agent.description}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Created: </strong>
            </Col>
            <Col>{agent.created_at}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>Type: </strong>
            </Col>
            <Col>{agent.custom_code === null || agent.custom_code === "" ? "AI" : "Calculation"}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              {/* <strong>{agent.custom_code ? "Custom Code" : "System Prompt"}: </strong> */}
              <strong>Prompt: </strong>
            </Col>
            <Col>
              {isEditing ? (
                <Form.Control
                  as="textarea"
                  rows={5}
                  name={agent.custom_code ? "custom_code" : "system_prompt"}
                  value={agent.custom_code ? editData.custom_code : editData.system_prompt}
                  onChange={handleInputChange}
                />
              ) : (
                agent.custom_code ? 
                <pre className="bg-light p-3 rounded">{agent.custom_code}</pre> :
                agent.system_prompt
              )}
            </Col>
          </Row>

          {(agent.custom_code === null || agent.custom_code === "") && (
            <>
              <Row>
                <Col xs={2}>
                  <strong>Model: </strong>
                </Col>
                <Col>{agent.model}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Frequency Penalty: </strong>
                </Col>
                <Col>{agent.frequency_penalty}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Max Tokens: </strong>
                </Col>
                <Col>{agent.max_tokens}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <strong>Temperature: </strong>
                </Col>
                <Col>{agent.temperature}</Col>
              </Row>
            </>
          )}

          {runResult && !Array.isArray(runResult) && (
            <Row>
              <Col xs={2}>
                <strong>Run Result: </strong>
              </Col>
              <Col>
                <ReactMarkdown>{String(runResult)}</ReactMarkdown>
              </Col>
            </Row>
          )}

          {runResult && Array.isArray(runResult) && (
            <Row className="mt-4">
              <Col>
                <h3>Run Result</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Segment</th>
                      <th>Index</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {runResult.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Segment}</td>
                        <td>{item.Index}</td>
                        <td>{item.Size}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <div className="mt-4">
            {agent.custom_code ? (
              // Calculation Agent
              <>
                <h3>Select Data</h3>
                <Form onSubmit={handleFileUpload}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Project</Form.Label>
                    <Form.Select 
                      value={selectedProject}
                      onChange={handleProjectChange}
                      required
                    >
                      <option value="">Choose a project...</option>
                      {projects
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select File</Form.Label>
                    <Form.Select 
                      value={selectedFile}
                      onChange={handleFileChange}
                      required
                      disabled={!selectedProject}
                    >
                      <option value="">Choose a file...</option>
                      {files
                        .filter(file => file.state === "merged")
                        .sort((a, b) => a.merged_filename_location.localeCompare(b.merged_filename_location))
                        .map((file) => {
                          const project = projects.find(p => p.id === parseInt(selectedProject));
                          const displayName = project ? 
                            `${project.customer.customer_name}/${file.merged_filename_location}` : 
                            file.merged_filename_location;
                          return (
                            <option key={file.id} value={file.merged_filename_location}>
                              {displayName}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </>
            ) : (
              // AI Agent
              <>
                <h3>Run Agent</h3>
                <Form.Group className="mb-3">
                  <Form.Label>User Prompt</Form.Label>
                  <Form.Control
                    type="text"
                    value={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Website URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={websiteUrl}
                    onChange={handleWebsiteUrlChange}
                  />
                </Form.Group>
              </>
            )}

            <Button 
              onClick={RunAgent} 
              disabled={isRunning}
            >
              {isRunning ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Running...
                </>
              ) : (
                'Run'
              )}
            </Button>
            &nbsp;
            <Button className="btn-warning" onClick={toggleEdit}>{isEditing ? "Save" : "Edit"}</Button>
          </div>
        </>
      )}
    </Container>
  );
}

export default ViewAgent;
