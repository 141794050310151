import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import UserFriendlyDate from '../../Utils/UserFriendlyDate';
import "../../../App.css"

import api from "../../../utils/agentApi";

function ListSequences() {
  const [sequences, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_sequences");
      setAgents(response.data.data);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleDelete = (id) => {

    return async () => {
      console.log(id);
      try {
        let sequenceData = {
          "seq_id": id
        };
        await api.post(`/remove_sequence`, sequenceData);
        const response = await api.get("/get_sequences");
        setAgents(response.data.data);
      } catch (error) {
        alert("Failed to delete customer");
      }
    };
  };


  return (
    <Container>
      {isLoading && <p>Loading sequences...</p>}
      {sequences.length === 0 && !isLoading && <p>No Sequences found.</p>}
      <h1>Sequences{" "}
        <Button href="/workflows/sequences/create" variant="outline-primary">
          New Sequence
        </Button></h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {[...sequences].sort((a, b) => {
            // Extract numbers from the start of the name (e.g., "5.3" from "5.3 BIQ - Creative Direction")
            const numA = parseFloat(a.name.match(/^\d+\.?\d*/)?.[0] || 0);
            const numB = parseFloat(b.name.match(/^\d+\.?\d*/)?.[0] || 0);
            return numA - numB;
          }).map((sequence) => (
            <tr key={sequence.id}>
              <td>{sequence.name}</td>
              <td>{sequence.description}</td>
              <td>
                <UserFriendlyDate timestamp={sequence.created_at} />
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-toggle">
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href={`/workflows/sequences/view/${sequence.id}`} className="custom-dropdown-item">
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(sequence.id)} className="custom-dropdown-item">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListSequences;
