import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, FormGroup, FormControl } from "react-bootstrap";
import api from "../../utils/api";

const Create = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [reportName, setReportName] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [reportType, setReportType] = useState("");
  const [customers, setCustomers] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    fetchCustomers().then(() => {
      const customerParam = searchParams.get('customer');
      if (customerParam) {
        setCustomerID(customerParam);
        const selectedCustomer = customers.find(c => c.id === parseInt(customerParam));
        if (selectedCustomer) {
          generateReportName(selectedCustomer.customer_name);
        }
      }
    });
  }, [searchParams]);

  const generateReportName = (customer_name) => {
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().toLocaleString([], {
      month: "long",
    });
    let timestamp = new Date().getTime();
    let name = customer_name ? customer_name : timestamp;

    let gen_name = `${currentMonth}_${currentYear}_${name}`;
    setReportName(gen_name);
  };

  const fetchCustomers = async () => {
    try {

      const response = await api.get("/customer/all");

      setCustomers(response.data); // Adjust based on your API structure
    } catch (error) {
      console.error("Failed to fetch customers", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", reportName);
    formData.append("description", reportDescription);
    formData.append("customer_id", customerID);
    formData.append("type", reportType);
    formData.append("state", "draft");

    const apiUrl = "/report/";
    const data = {
      name: reportName,
      description: reportDescription,
      customer_id: customerID,
      type: reportType,
      state: "draft",
    };

    try {
      const response = await api.post(apiUrl, data);
      console.log(response.data.id);
      navigate(`/report/view/${response.data.id}`);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      setUploadProgress(0); // Reset progress
    }
  };

  return (
    <div className="container">
      <h1 className="mt-4">Create New Project</h1>

      <FormGroup className="mb-3">
        <Form.Label>Customer</Form.Label>

        <Form.Select
          id="customerID"
          value={customerID}
          onChange={(e) => {
            generateReportName(e.target.options[e.target.selectedIndex].text);
            setCustomerID(e.target.value);
          }}
        //onChange={(e) => setCustomerID(e.target.value)}
        >
          <option value="">Select a customer</option>
          {customers.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.customer_name}
            </option>
          ))}
        </Form.Select>
      </FormGroup>

      <Form>
        <FormGroup className="mb-3">
          <Form.Label>Project name</Form.Label>
          <FormControl
            type="text"
            placeholder={reportName}
            id="reportName"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="mb-3">
          <Form.Label>Project type</Form.Label>
          <Form.Select
            id="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <option>Select a report type</option>
            <option value="customer">Customer-level</option>
            <option value="campaign">Campaign-level</option>
            {/* Add more customers here */}
          </Form.Select>
        </FormGroup>

        <FormGroup className="mb-3">
          <Form.Label>Description</Form.Label>
          <FormControl
            as="textarea"
            rows={3}
            id="reportDescription"
            value={reportDescription}
            onChange={(e) => setReportDescription(e.target.value)}
          />
        </FormGroup>

        {/*         <Form.Group className="mb-3" controlId="reportTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control type="text" placeholder="Add tags" />
        </Form.Group> */}

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Create Project
        </Button>
      </Form>
    </div>
  );
};

export default Create;
