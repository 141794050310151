import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import api from "../../utils/api";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import "../../App.css"
const List = () => {
  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    const fetchPrompts = async () => {
      setIsLoading(true);
      const response = await api.get("/prompts/all");
      setPrompts(response.data);
      setIsLoading(false);
    };
    fetchPrompts();
  }, [sortConfig]);

  const sortItems = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig !== null) {
      setPrompts(
        prompts.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        })
      );
    }
  }, [sortConfig]);

  const handleDelete = (id) => {
    return async () => {
      setIsLoading(true);
      try {
        // Change POST to DELETE and include the id in the URL
        await api.delete(`/prompts/${id}`);

        // Fetch updated list of prompts
        const response = await api.get("/prompts/all");
        setPrompts(response.data);
      } catch (error) {
        console.error("Failed to delete prompt", error);
      }
      setIsLoading(false);
    };
  };

  return (
    <Container>
      {isLoading && <p>Loading prompts...</p>}
      {prompts.length === 0 && !isLoading && <p>No prompts found.</p>}
      <h1>
        Prompts
        <Button href="/prompts/create" variant="outline-primary">
          Add
        </Button>
      </h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => sortItems("name")}>
              Name
              {sortConfig &&
                sortConfig.key === "name" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("description")}>
              Description
              {sortConfig &&
                sortConfig.key === "description" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("prompt")}>
              Prompt
              {sortConfig &&
                sortConfig.key === "prompt" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("created_at")}>
              Created
              {sortConfig &&
                sortConfig.key === "created_at" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th onClick={() => sortItems("updated_at")}>
              Updated
              {sortConfig &&
                sortConfig.key === "updated_at" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prompts.map((prompt) => (
            <tr key={prompt.id}>
              <td>
                {prompt.name}
              </td>
              <td>{prompt.description}</td>
              <td>
                <small>{prompt.prompt}</small>
              </td>
              <td>
                <UserFriendlyDate timestamp={prompt.created_at} />
              </td>
              <td>
                <UserFriendlyDate timestamp={prompt.updated_at} />
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-toggle">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu" >
                    <Dropdown.Item href={`/prompts/edit/${prompt.id}`} className="custom-dropdown-item" >
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(prompt.id)} className="custom-dropdown-item">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default List;
