import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import api from "../../utils/api";
import "./List.css";
const List = () => {
  const [listCustomers, setListCustomers] = useState([]);

  useEffect(() => {
    const fetchcustomers = async () => {
      try {
        const response = await api.get("/customer/all");
        setListCustomers(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    };
    const fetchcustomer = async () => {
      const response = await api.get(`/customer/155`);
      console.log(response.data);
    };

    fetchcustomers();
    fetchcustomer();
  }, []);

  const handleDelete = (id) => {
    return async () => {
      try {
        await api.delete(`/customer/${id}`);
        const response = await api.get("/customer/all");
        setListCustomers(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to delete customer", error);
      }
    };
  };

  return (
    <Container>
      <h1 className="mt-4">
        All Customers{" "}
        <Button href="/customer/create" variant="outline-primary">
          Add
        </Button>
      </h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {[...listCustomers].sort((a, b) => {
            // Convert IDs to numbers for proper numerical sorting
            const idA = parseInt(a.id);
            const idB = parseInt(b.id);
            return idA - idB;
          }).map((customer) => (
            <tr key={customer.id}>
              <td>{customer.id}</td>
              <td>{customer.customer_name}</td>
              <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle 
                    variant="success" 
                    id="dropdown-basic"
                    className="custom-dropdown-toggle"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item 
                      href={`/customer/view/${customer.id}`}
                      className="custom-dropdown-item"
                    >
                      View
                    </Dropdown.Item>
                    <Dropdown.Item 
                      href={`/customer/${customer.id}/projects`}
                      className="custom-dropdown-item"
                    >
                      Projects
                    </Dropdown.Item>
                    <Dropdown.Item 
                      as="button" 
                      onClick={handleDelete(customer.id)}
                      className="custom-dropdown-item"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default List;
