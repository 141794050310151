import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table, Dropdown, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import api from "../../utils/api";

const CustomerReports = () => {
  const [listReports, setListReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [customer, setCustomer] = useState(null);
  const { id } = useParams(); // This will get the customer ID from the URL

  // Fetch reports when the component is mounted
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get(`/customer/${id}/reports`);
        // Sort the reports alphabetically by name before setting state
        const sortedReports = response.data.sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        setListReports(sortedReports);
        
        // Optionally fetch customer details to show the customer name in the header
      } catch (error) {
        console.error("Failed to fetch reports", error);
      }
    };
    const fetchCustomer = async () => {
      try {
        const response = await api.get(`/customer/${id}`);
        setCustomer(response.data);
        console.log("customer", response.data);
      } catch (error) {
        console.error("Failed to fetch customer details:", error);
        // Optionally add user notification here if needed
      }
    };

    fetchReports();
    fetchCustomer();
  }, [id]);

  // Handle delete button click
  const handleDeleteClick = (report) => {
    setReportToDelete(report);
    setShowModal(true);
  };

  // Confirm and delete the report
  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/report/${reportToDelete.id}`);
      setListReports(listReports.filter((report) => report.id !== reportToDelete.id));
      setShowModal(false);
    } catch (error) {
      console.error("Failed to delete report", error);
    }
  };

  return (
    <Container>
      <h1 className="mt-4">
        Projects for {customer?.customer_name}{" "}
        <Button href={`/report/create?customer=${id}`} variant="outline-primary">
          Add
        </Button>
      </h1>

      {listReports.length === 0 ? (
        <div className="text-center mt-4 p-4 bg-light rounded">
          <p className="mb-0">No projects found for this customer.</p>
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Created</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {listReports.map((report) => (
              <tr key={report.id}>
                <td>{report.name}</td>
                <td>{report.type}</td>
                <td>
                  <UserFriendlyDate timestamp={report.created_at} />
                </td>
                <td>{report.state}</td>
                <td width="120px">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`/report/view/${report.id}`}>
                        View
                      </Dropdown.Item>
                      <Dropdown.Item as="button" onClick={() => handleDeleteClick(report)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this report?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CustomerReports;